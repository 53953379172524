import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Background from "../background";

const OsHeroWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .hero_img{
    position: relative;
    z-index: 2;
    @media only screen and (min-width: 769px){
      display:none;
    }
  }

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
    
  }

  @media only screen and (max-width: 1024px){
    margin-left: 4rem;
    
  }

  @media only screen and (max-width: 768px){
    margin-left: auto;
    margin-right: auto;
    
  }
`;
const OsHeroRowOne = styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;

  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--orange);
    padding: 2rem 4rem 0 4rem;
    margin-top: 1rem;
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 105.4%;
    /* or 42px */

    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    padding: 0 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 769px){
    display: none;
  }
`;

const OsHeroRowTwo = styled.div`
  top:-65px;
  position:relative;
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;

  .osHeroRowTwo__text{
    padding-top: 3.5rem;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--orange);
    position:relative;
    z-index: 0;
    margin-bottom: 2.5rem;

    @media only screen and (min-width: 501px){
      padding-top: 5rem;
      margin-bottom: 3.5rem;
    }
  }

  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 129.4%;
    /* or 26px */
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;

    @media only screen and (min-width: 501px){
      font-size: 25px;
      padding: 0 5.1rem;
    }
  }

  .osHeroRowTwo__topText{
    padding: 3rem 2rem 0rem 2rem;
    margin-bottom: 0;
  }

  .osHeroRowTwo__botText{
    margin-top:0;
    padding: 0rem 2rem 1rem 2rem;

    @media only screen and (min-width: 501px){
      padding-bottom: 2rem;
    }
  }

  @media only screen and (min-width: 769px){
    display:none;
  }
`;

const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 26%;
  height: 75%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px){
    background-color: var(--orange);
    height: 75%;
  }
`;

const OsDesktopHeroRow = styled.div`
  position: relative;
  display:grid;
  grid-template-columns: 59% 1fr;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 7rem;

  .button__container{
    width: auto;

    @media only screen and (min-width: 769px){
      width: 100%;
    }
  }

  .hire_button--desktop{
    @media only screen and (min-width: 1025px){
      width: 100%;
    }
  }

  .desktop_rowOne__text_container{
    display:flex;
    width:100%;
    flex-direction: column;
    align-items: flex-start;
    /* padding-left: 5rem; */
    padding-top: 3rem;

    h1{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      letter-spacing: -0.005em;
      color: var(--orange);
      margin-bottom: 2rem;

    }

    h4{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      line-height: 105%;
      letter-spacing: -0.005em;
      color: var(--blue);
      margin-bottom: 3rem;

      @media only screen and (max-width: 1250px){
        font-size:40px;
      }

      @media only screen and (max-width: 1024px){
        font-size:35px;
      }
    }

    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 152.9%;
      color: #000000;
      margin-top:0;
      margin-bottom: 3rem;
      padding-right: 7rem;

      @media only screen and (max-width: 1250px){
        font-size:22px;
      }

      @media only screen and (max-width: 1024px){
        font-size:20px;
      }
    }

    @media only screen and (min-width: 1441px){
      padding-right: 5rem;
    }
  }
  

  .desktop_rowOne__image_container{
    position:relative;
    width:100%;
    height:100%;
    z-index: 1;
  }

  .hero__image--desktop{
    width: 100%;
    /* max-width: 600px; */
    aspect-ratio: 635/400;
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @media only screen and (max-width: 1024px){
    grid-template-columns: 65% 1fr;
  }

  @media only screen and (max-width: 768px){
    display:none;
  }
`;

const OsHero = ({ content }) => {
  const { imageAssets, bodyText, orangeBackgroundRectangle } = content[0];
  return (
    <OsHeroWrapper>
      {/* hero row one */}
      <OsDesktopHeroRow>
        <Background/>
        <div className="desktop_rowOne__text_container">
          <h1>{bodyText[0].text}</h1>
          <h4>{bodyText[1].text}</h4>
          <p>{bodyText[2].text}&nbsp;{bodyText[3].text}</p>
          <div className="button__container">
            <Link to="/contact-us"><button className='hire_button hire_button--desktop'>Hire Us</button></Link>
          </div>
          
        </div>
        <div className="desktop_rowOne__image_container">
          {/* <img alt={imageAssets[1].title} className="hero__image hero__image--desktop" src={imageAssets[1].url}/> */}
          <GatsbyImage alt={imageAssets[1].title} className="hero__image hero__image--desktop" image={getImage(imageAssets[1].gatsbyImageData)}/>
        </div>
        <OrangeDesktopBackground>
          <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
        </OrangeDesktopBackground>
      </OsDesktopHeroRow>

      <OsHeroRowOne>
        <Background/>
        <h1>{bodyText[0].text}</h1>
        <h4>{bodyText[1].text}</h4>
      </OsHeroRowOne>
      {/* image */}
      <GatsbyImage alt={imageAssets[0].title} className="hero_img" image={getImage(imageAssets[0].gatsbyImageData)}/>
      {/* hero row two */}
      <OsHeroRowTwo>
        <div className="osHeroRowTwo__text">
          <Background color={"gray"} show={"hidden"}/>
          <p>{bodyText[2].text}</p>
          <p className='osHeroRowTwo__botText'>{bodyText[3].text}</p>
        </div>
        <div className="button__container">
          <Link to="/contact-us"><button className='hire_button'>Hire Us</button></Link>
        </div>
        {/* <button className='hire_button'>Hire Us</button> */}
      </OsHeroRowTwo>
    </OsHeroWrapper>
  );
};

export default OsHero;