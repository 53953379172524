import React from "react";
import styled from "styled-components";
import Background from "../background";

const OsWhoWeAreWrapper = styled.div`
  display:flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 1440px;
  position: relative;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;

  .section_title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--orange);
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 105.4%;
    /* or 32px */

    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    padding: 0 0.5rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: 501px){
      font-size: 35px;
    }
  }

  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 152.9%;
    /* or 28px */

    text-align: center;
    letter-spacing: -0.005em;
    color: #000000;
    
  }

  .wwa_topText{
    padding: 0 1.75rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 1rem;
      padding: 0 5.1rem;
      margin-bottom: 2rem;
    }

    @media only screen and (min-width: 769px){
      padding: 0;
    }
  }

  .wwa_botText{
    padding: 0 2.1rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 5.1rem;
    }

    @media only screen and (min-width: 769px){
      padding: 0;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-top: 5rem;
  }

  @media only screen and (min-width: 769px){
    /* padding:0 5rem 0 5rem; */
    align-items: flex-start;
    margin-bottom: 10rem;

    h2{
      margin-bottom: 1.5rem;
    }

    h4{
      width:48%;
      text-align: left;
      font-size: 45px;
      line-height: 129.9%;
      padding: 0;

      @media only screen and (max-width: 1250px){
        font-size: 40px;
        width: 52%;
      }

      @media only screen and (max-width: 1024px){
        font-size: 35px;
        width: 60%;
      }
    }

    p{
      font-size: 23px;
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 1rem;

      @media only screen and (max-width: 1250px){
        font-size: 21px;
      }

      @media only screen and (max-width: 1024px){
        font-size: 19px;
      }
    }
  }

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
  }

  @media only screen and (max-width: 1024px){
    margin-left: 4rem;
  }

  @media only screen and (max-width: 768px){
    margin-left: auto;
    margin-right: auto;
  }
`;

const OsWhoWeAre = ({ content }) => {
  const { bodyText } = content[0];
  return (

    <OsWhoWeAreWrapper>
      <Background/>
      <p className="section_title">{bodyText[0].text}</p>
      <h4>{bodyText[1].text}</h4>
      <p className='wwa_topText'>{bodyText[2].text}</p>
      <p className='wwa_botText'>{bodyText[3].text}</p>
    </OsWhoWeAreWrapper>

  );
};

export default OsWhoWeAre;