import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const OsCardWrapper = styled.div`
  /* width: 324px; */
  display:flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 2rem;
  position: relative;
  margin-bottom: 3rem;
  box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.25);
  justify-content: space-evenly;

  .card_icon__image{
    @media only screen and (max-width: 768px){
      position: absolute;
      width: 77px;
      aspect-ratio: 1;
      top: 5%;
      left: 10%;
    }
  }

  /* testing */
  .header__container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;

    @media only screen and (min-width: 769px){
      display: none;
    }

    @media only screen and (max-width: 500px){
      padding-left: 0;
    }
  }

  .desktop__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 2rem;
    @media only screen and (max-width: 768px){
      display: none;
    }
  }

  .card_icon__image{
    max-width: 105px;
  }

  .card_icon__image--mobile{
    @media only screen and (max-width: 500px){
      max-width: 95px;
    }
  }

  .bullet__container{
    width:90%;
  }

  .header__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 123.4%;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-left: 1rem;

    @media only screen and (min-width: 769px){
      font-size: 22px;
      position:static;
      width:100%;
      text-align: left;
      margin-left: 0;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    @media only screen and (min-width: 1025px){
      font-size: 25px;
      position:static;
      width:100%;
      text-align: left;
      margin-left: 0;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    @media only screen and (min-width: 1251px){
      font-size: 30px;
      position:static;
      width:100%;
      text-align: left;
      margin-left: 0;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

  }

  .bullet_container__text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 200.9%;
    /* or 38px */
    letter-spacing: -0.005em;
    color: var(--blue);
    line-height: 120%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media only screen and (min-width: 1025px){
      font-size: 20px;
    }

    @media only screen and (min-width: 1251px){
      font-size: 21px;
    }
  }

  .bullet_container__text::marker{
    color: var(--orange);
  }

  @media only screen and (min-width: 769px){
    width: 96%;
    height: 99%;
    align-items: flex-start;
    padding: 0 2rem;
    justify-content: center;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    width: 445px;
    height: 507px;
  }

  @media only screen and (max-width: 500px){
    padding: 1rem;
    width: 324px;
  }
`;

const OsCard = ({ card }) => {
  const { cardTitle, cardImage, cardBullets } = card;
  return (
    <OsCardWrapper>
      <div className="header__container">
        <GatsbyImage alt="card_icon__image" className="card_icon__image--mobile" image={getImage(cardImage.gatsbyImageData)}/>
        <p className='header__text'>{cardTitle}</p>
      </div>

      <div className="desktop__container">
        <GatsbyImage alt="card_icon__image" className="card_icon__image" image={getImage(cardImage.gatsbyImageData)}/>
        <p className='header__text'>{cardTitle}</p>
      </div>
      {/*  */}
      <div className="bullet__container">
        <ul>
          {cardBullets.map((bullet, index) => {
            return (
              <li className="bullet_container__text" key={index}>{bullet.bullet}</li>
            );
          })}
        </ul>
      </div>
    </OsCardWrapper>
  );
};

export default OsCard;