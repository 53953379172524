import React from "react";
import Layout from "../layouts";
import { graphql } from "gatsby";

//Components
import OsHero from "../components/ourServices/osHero";
import OsWhoWeAre from "../components/ourServices/osWhoWeAre";
import OsExpertise from "../components/ourServices/osExpertise";
import OsPricing from "../components/ourServices/osPricing";
import SEO from "../components/seo";

const OurServices = ({ data }) => {
  const { nodes } = data.allContentfulOurServicePage;
  const hero = nodes.filter(node => node.sectionTitle === "01_Our_Services - Hero");
  const wwa = nodes.filter(node => node.sectionTitle === "02_Our_Services - Who We Are");
  const serv = nodes.filter(node => node.sectionTitle === "03_Our_Services - Expertise");
  const price = nodes.filter(node => node.sectionTitle === "04_Our_Services - Pricing");

  return (
    <Layout>
      <SEO 
        description="FFG has got you covered with any marketing, design, or development need your brand has. This includes everything from Meta ads to SEO to email marketing."
        title="Our Services | Full Funnel Growth" 
      />
      <OsHero content={hero}/>
      <OsWhoWeAre content={wwa}/>
      <OsExpertise content={serv}/>
      <OsPricing content={price} />
    </Layout>
  );
};

export default OurServices;

export const query = graphql`
query OurServicesQuery {
  allContentfulOurServicePage {
    nodes {
      sectionTitle
      imageAssets {
        gatsbyImageData(
          quality: 100, 
          placeholder: BLURRED
        )
        title
        url
      }
      bodyText {
        text
      }
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
      cardComponents {
        references {
          cardImage {
            gatsbyImageData(
              quality: 100,
              placeholder: BLURRED
            )
          }
          cardTitle
          cardBullets {
            bullet
          }
        }
      }
    }
  }
}

`; 